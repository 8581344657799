import MuiInputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import { typography } from "../../theme";
import HelpText from "./HelpText";

const InputBase = ({ sx = undefined, showHelpText = false, ...props }) => {
  return (
    <>
      <MuiInputBase
        {...props}
        inputProps={{
          maxLength: props?.maxLength,
          minLength: props?.minLength,
          ...props.inputProps,
        }}
        sx={{
          "&.Mui-disabled": {
            borderColor: "grey300.main",
            backgroundColor: "grey100.main",
          },

          "& .MuiInputBase-input": {
            display: "flex",
            alignItems: "center",
            gap: "6px",
            minHeight: "16px",
            lineHeight: "16px",
            padding: 0,

            "& .MuiSvgIcon-root": {
              fontSize: "16px",
              color: "grey500.main",
              margin: 0,
            },
            "&:focus": {
              background: "none",
            },
          },
          gap: "6px",
          borderRadius: "6px",
          border: "1px solid",
          borderColor: "grey300.main",
          background: "white",
          padding: "10px",

          height: "36px",
          minHeight: "16px",
          lineHeight: "16px",
          whiteSpace: "nowrap",

          "& input": {
            padding: 0,
            ...typography.body1,
            "&::-webkit-input-placeholder": {
              color: "grey600.main",
              opacity: 1,
            },
          },
          "& .MuiSvgIcon-root.MuiSelect-icon": {
            fontSize: "24px",
            color: "grey500.main",
          },
          "& .MuiSelect-iconStandard": {
            marginRight: "6px",
          },

          ".Mui-error": {
            backgroundColor: "red400.main",
          },

          ...sx,
        }}
      />

      {showHelpText && props.maxLength ? (
        <HelpText value={props.value} maxLength={props.maxLength} />
      ) : null}
    </>
  );
};

InputBase.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default InputBase;
