import axios from "axios";
import isSet from "./helpers/validate";
import { action as loadingAction } from "./modules/loading";
import { action as loginAction } from "./modules/login";
import store from "./store";

export const baseServer = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 500000,
});

export const apiRequest = options => {
  const { dispatch, getState } = store;
  const { pending, success, fail, setFailMessage } = loadingAction;

  if (options.url !== "/login/") {
    const token = getState().login.token;
    if (isSet(token) && token !== "null")
      baseServer.defaults.headers.common["Authorization"] = token;
  }

  // 로딩이 필요한 경우 (기본값)
  if (options.loading !== false) dispatch(pending());
  return new Promise((resolve, reject) => {
    baseServer(options)
      .then(res => {
        resolve(res);
        if (options.loading !== false) dispatch(success());
      })
      .catch(error => {
        if (error?.response?.status === 403) {
          dispatch(loginAction.logout());
        }

        // 공통 에러 메세지 (기본값)
        if (options.errorAlert !== false) {
          let errorMsg;
          if (error.response) {
            switch (error.response.status) {
              case 403:
                errorMsg = "로그인 기한이 만료되었습니다. 다시 로그인해주세요.";
                break;

              case 500:
                errorMsg = "예기치 못한 에러가 발생하였습니다.\n관리자에게 문의해주세요.";
                break;

              default:
                errorMsg = `[${error.response.status}] 에러 발생`;
                if (error.response?.data?.message) errorMsg = error.response?.data?.message;
                else if (typeof error.response?.data !== "object" && error.response?.data)
                  errorMsg = error.response?.data;
                else if (typeof error.data === "object") {
                  if (error.data.result) errorMsg = error.data.result;
                  else if (error.data.message) errorMsg = error.data.message;
                }
            }
          } else {
            errorMsg = options?.errorMsg || error.message;
          }
          dispatch(setFailMessage(errorMsg));
        }

        if (options.loading !== false) dispatch(fail());
        reject(error);
      });
  });
};
