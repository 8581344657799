import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Base, { tooltipClasses } from "@mui/material/Tooltip";
import { styled as muiStyled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import { CustomErrorIcon } from "../Icons";

export const colorProps = ["primary", "error", "point"];

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  color: #ffffff;
  text-align: left;
  * {
    margin: 0;
    word-break: break-all;
    white-space: pre-line;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-weight: 400;
  font-size: 13px;
  line-height: 150%;

  a {
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%; /* 16.5px */
    text-decoration: underline;
  }

  p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  ul {
    padding-left: 20px;
  }

  img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  gap: 6px;
  width: fit-content;
  cursor: default;
`;

const RecommendBadge = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  text-align: right;
  line-height: 12px;
  background: linear-gradient(90deg, #5336ff -0.29%, #ff528d 100%);
  border-radius: 4px;
  width: auto;
`;

const getColor = color => {
  switch (color) {
    case "error":
      return "#fb545e";
    case "point":
      return "#FF508C";
    case "primary":
    default:
      return "#484760";
  }
};

const StyledComponent = muiStyled(({ className, ...props }) => {
  return (
    <Base
      {...props}
      classes={{ popper: className }}
      PopperProps={{
        disablePortal: true,
        ...props.PopperProps,
      }}
    />
  );
})(({ color, maxWidth, autoFocus, tooltipStyles }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: getColor(color),
    fontSize: "12px",
    marginTop: "1px",
    "&:before": {
      borderRadius: "1px",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth ? maxWidth : 400,
    padding: 10,
    color: "#fff",
    backgroundColor: getColor(color),
    border: "none",
    borderRadius: "6px",
    margin: 12,
    ...tooltipStyles,
  },
  zIndex: autoFocus ? 0 : "none",
  width: "max-content",
}));

const Tooltip = ({
  title,
  text,
  children,
  autoFocus,
  hideTitleIcon,
  statusBadgeText,
  onMouseEnter,
  onMouseLeave,
  ...props
}) => {
  const [open, setOpen] = useState(autoFocus ?? undefined);

  // onMouseEnter와 onMouseLeave가 boolean일 때도 함수로 처리
  const handleMouseEnter = typeof onMouseEnter === "boolean" ? () => setOpen(true) : onMouseEnter;
  const handleMouseLeave = typeof onMouseLeave === "boolean" ? () => setOpen(false) : onMouseLeave;

  return (
    <StyledComponent
      autoFocus={autoFocus}
      title={
        <Content onClick={() => setOpen(false)}>
          {title && (
            <Title>
              {!hideTitleIcon &&
                (props.color === "error" ? (
                  <CustomErrorIcon fontSize="xsmall" />
                ) : (
                  <HelpOutlineRoundedIcon color="grey500" fontSize="xsmall" />
                ))}

              {statusBadgeText && <RecommendBadge>{statusBadgeText}</RecommendBadge>}
              {title}
            </Title>
          )}
          {text && <Text>{text}</Text>}
        </Content>
      }
      open={Boolean(title || text) && open}
      arrow
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <Wrapper>{children}</Wrapper>
    </StyledComponent>
  );
};

Tooltip.propTypes = {
  color: PropTypes.oneOf(colorProps),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  autoFocus: PropTypes.bool,
  hideTitleIcon: PropTypes.bool,
  statusBadgeText: PropTypes.string,
};

Tooltip.defaultProps = {
  color: "primary",
  hideTitleIcon: false,
  placement: "top",
};

export default Tooltip;
