import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import Symbol from "../Icons/Symbol";

const HelpText = ({ value, maxLength }) => {
  if (!maxLength) return;

  return value?.length > maxLength ? (
    <Typography sx={{ justifyContent: "space-between" }}>
      <Typography variant="body1" color="text.red400">
        <Symbol name="error" variant="contained" />
        {maxLength}글자 미만으로 입력해주세요.
      </Typography>
      <Typography variant="body2" color="text.red400" sx={{ justifyContent: "flex-end" }}>
        {value?.length ?? 0} / {maxLength}
      </Typography>
    </Typography>
  ) : (
    // 글자 수 표기
    <Typography variant="body2" color="text.primary800" sx={{ justifyContent: "flex-end" }}>
      {value?.length ?? 0} / {maxLength}
    </Typography>
  );
};

HelpText.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.number,
};

export default HelpText;
