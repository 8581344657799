import { call, put, select, takeLatest } from "redux-saga/effects";
import isSet from "../helpers/validate";
import { action as loginAction } from "../modules/login";
import { action as authPhoneAction } from "../modules/modal/authPhone";
import { apiRequest } from "../server_url";

function* login({ payload }) {
  try {
    const { id, password } = payload;
    const response = yield call(apiRequest, {
      url: "/login/",
      method: "POST",
      data: {
        id: id,
        pw: password,
      },
      errorAlert: false,
    });

    let { token, login_phone } = response.data;
    if (isSet(login_phone)) {
      yield put(authPhoneAction.open({ id, password, login_phone }));
    } else {
      yield put(loginAction.loginSuccess(token));
    }
  } catch (error) {
    const data = error.response?.data;

    if (data?.is_closed) {
      payload.onError();
    } else if (data?.message) {
      alert(data.message);
    } else {
      alert(`[${error.response.status}] 에러 발생`);
    }
  }
}

const authPhoneState = state => state.authPhone;
function* loginWithSMS(action) {
  try {
    const { id, password } = yield select(authPhoneState);
    let response = yield call(apiRequest, {
      url: "/login/with/sms/",
      method: "POST",
      data: {
        id: id,
        pw: password,
        auth: action.payload,
      },
    });

    yield put(loginAction.loginSuccess(response.data.token));
  } catch (error) {}
}

function* postAuthNumber() {
  try {
    const { id, password } = yield select(authPhoneState);
    yield call(apiRequest, {
      url: "/login/sms/auth/",
      method: "POST",
      data: {
        id: id,
        pw: password,
      },
    });
  } catch (error) {}
}

export function* loginAsync() {
  yield takeLatest(loginAction.login, login);
  yield takeLatest(loginAction.loginWithSMS, loginWithSMS);
  yield takeLatest(authPhoneAction.postAuthNumber, postAuthNumber);
}
