import FontFaceObserver from "fontfaceobserver";
import { useEffect } from "react";
import { createGlobalStyle } from "styled-components";

const useGlobalStyle = createGlobalStyle`
  .font-failed .material-symbols-rounded,
  .font-loading .material-symbols-rounded {
    visibility: hidden;
  }
`;

const useFontLoader = () => {
  useEffect(() => {
    const maxRetries = 5;
    const loadFont = async (retries = maxRetries) => {
      try {
        console.log(
          `Attempting to load font (attempt ${maxRetries + 1 - retries}/${maxRetries})...`
        );
        const font = new FontFaceObserver("Material Symbols Rounded");
        const result = await font.load(null, 5000);
        document.body.classList.remove("font-loading");
        console.log("Font loaded successfully:", result);
      } catch (err) {
        console.error(`Font loading failed (attempts left: ${retries - 1}):`, err);

        if (retries > 1) {
          console.log("Retrying font load...");
          await loadFont(retries - 1);
        } else {
          console.error("All font loading attempts failed");
          document.body.classList.add("font-failed");
        }
      } finally {
        console.log(`Font loading attempt ${maxRetries + 1 - retries}/${maxRetries} completed`);
      }
    };

    loadFont().catch(err => {
      console.error("Unexpected error in loadFont:", err);
    });
  }, []);

  return useGlobalStyle;
};

export default useFontLoader;
