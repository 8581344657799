import { Modal as MuiModal } from "@mui/material";
import styled from "styled-components";

const CustomModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
`;

const ModalPaper = styled.div`
  position: absolute;

  max-height: calc(100% - 80px);
  overflow-y: ${({ isSetScroll }) => (isSetScroll ? "scroll" : "visible")};
  * {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  outline: none;
  min-width: 376;
  width: ${({ width }) => (width ? `${width}px` : "376px")};
  box-shadow:
    rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 5px 8px 0px,
    rgb(0 0 0 / 12%) 0px 1px 14px 0px;
  background-color: #fff;

  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #484760;
  /* margin-bottom: 16px; */
`;

const Modal = ({ id, isBlockBackdropClick, close, width, children, isSetScroll = true }) => {
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };
  return (
    <CustomModal
      id={id}
      open={true}
      onClose={(event, reason) => {
        if (!isBlockBackdropClick || reason !== "backdropClick") close && close();
      }}
      onKeyDown={handleKeyDown}
      disableEscapeKeyDown
    >
      <ModalPaper width={width} isSetScroll={isSetScroll}>
        {children}
      </ModalPaper>
    </CustomModal>
  );
};

export default Modal;
