import { dateToString } from "@babitalk/utils";
import koLocale from "date-fns/locale/ko";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { Calendar } from "react-date-range";
import styled from "styled-components";
import { CustomCalendarIcon } from "../Icons";
import InputBase from "../Input/Base";

// props: date, maxDate, minDate, position
const DatePicker = ({ error, focusProps, inputStyle, onChange, disabled = false, ...props }) => {
  const [datePickerFocus, setDatePickerFocus] = useState(false);

  useEffect(() => {
    if (focusProps?.isFocus) {
      setDatePickerFocus(true);
      focusProps.setIsFocus(false);
    }
  }, [focusProps]);

  const handleSelect = date => {
    if (props.minDate && dayjs(date).isBefore(props.minDate, "day")) {
      date = new Date(props.minDate);
    }
    if (props.maxDate && dayjs(date).isAfter(props.maxDate, "day")) {
      date = new Date(props.maxDate);
    }

    if (disabled) {
      date = new Date(props.date);
    }

    onChange(date);
    setDatePickerFocus(false);
  };

  return (
    <Wrapper ref={props?.inputRef}>
      <InputBase
        type="text"
        className={`form-control date_picker ${error ? "error" : ""}`}
        value={props?.date ? dateToString(new Date(props.date)) : "날짜를 선택하세요."}
        onClick={() => setDatePickerFocus(true)}
        sx={{ maxWidth: "162px", ...inputStyle }}
        startAdornment={<CustomCalendarIcon fontSize="xsmall" color="grey500" />}
        readOnly
        disabled={disabled}
      />
      {datePickerFocus && (
        <>
          <BackDim onClick={() => setDatePickerFocus(false)} />
          <DatePickerStyle
            locale={koLocale}
            dateDisplayFormat="yyyy-MM-dd"
            date={props?.date || new Date()}
            minDate={props?.minDate ? new Date(props?.minDate) : undefined}
            maxDate={props?.maxDate ? new Date(props?.maxDate) : undefined}
            onChange={handleSelect}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  position: relative;
`;

const BackDim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const DatePickerStyle = styled(Calendar)`
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid #ebebeb;
  margin-top: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

export default DatePicker;
